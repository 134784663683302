import {
	builder,
	type FilterStateForContractListing,
	type FilterStateForInvoiceListing,
	type FilterStateForSaleListing,
	type WkObjectExtended
} from '@blg/well-known-util';
import {parser} from '@blg/well-known-util';
import {type WkLink, type WkObject} from '@blg/well-known-util';
import type {SiteConfig} from '../siteConfig';
import {siteConfigStore} from '$stores/siteConfig';
import {get} from 'svelte/store';
import type ApiClient from '@blg/jsapilib';
import {goto} from '$app/navigation';
import {snakeCase} from '../formatters';
import {getRoutePath} from '$lib/utils/navigation/navigation';

export function handleViewLink(wellKnown: WkObjectExtended): void {
	const {action} = wellKnown;

	// -- List
	if (!action) {
		console.warn('Action is not defined !');
	} else if (action === 'list') {
		return handleListAction(wellKnown);
		// -- View
	} else if (action === 'view') {
		return handleViewAction(wellKnown);

		// -- Target
	} else if (action === 'target') {
		return handleTargetAction(wellKnown);

		// -- Public
	} else if (action === 'public') {
		return handlePublicAction(wellKnown);
	}

	console.warn(`Action ${action} is not available !`);
}

export function handleViewAction(wellKnown: WkObjectExtended): void {
	const location =
		wellKnown.target.namespace + '/' + wellKnown.target.objectName;
	const itemId = wellKnown.id.toString();
	let path;
	switch (location) {
		case 'equipment/equipment':
			path = getRoutePath('equipment_view', {itemId});
			break;
		case 'contract/contract':
			path = getRoutePath('contract_view', {itemId});
			break;
		case 'invoice/invoie':
			path = getRoutePath('invoice_view', {itemId});
			break;
		default:
			break;
	}

	if (path) {
		goto(path);
		return;
	}
}

export function handleTargetAction(wellKnown: WkObjectExtended): void {
	console.info(`Action ${wellKnown.action} is not implemented yet`);
}

export function handlePublicAction(wellKnown: WkObjectExtended): void {
	console.info(`Action ${wellKnown.action} is not implemented yet`);
}

export function handleListAction(wellKnown: WkObjectExtended): void {
	const {target, filterState} = wellKnown;
	const {namespace, objectName} = target;
	const location = `${namespace}/${objectName}`;

	// Contract Listing
	if (location == 'contract/contractLine') {
		return redirectToContractList(
			filterState as FilterStateForContractListing
		);
		// Sale Listing
	} else if (location == 'sale/saleQuoteLine') {
		return redirectToSaleList(filterState as FilterStateForSaleListing);
	}
	// Invoice Listing
	else if (location == 'invoice/invoiceLine') {
		return redirectToInvoiceList(
			filterState as FilterStateForInvoiceListing
		);
	}
	// Fleet Listing
	else if (location == 'equipment/equipment') {
		return redirectToFleetList();
	}

	// TODO : Add other listing here
}

export function redirectToInvoiceList(
	filterState: FilterStateForInvoiceListing
): void {
	let url = `/invoice`;

	const invoiceStatus =
		filterState?.u?.['special_invoice.status']?.['invoice.status.id']?.e;

	if (invoiceStatus) {
		const newFilterState = {
			f: {
				a: 'invoice/invoice',
				ff: {
					status: {
						s: [
							{
								v: invoiceStatus,
								l: invoiceStatus
							}
						]
					}
				}
			}
		};

		const encoded = encodeURIComponent(
			JSON.stringify(newFilterState, null, 0)
		);
		url += `?f=${encoded}`;
	}

	goto(url);
}
export function redirectToContractList(
	filterState: FilterStateForContractListing
): void {
	const type =
		filterState?.u?.['special_contract.type']?.['contract.type']?.e;

	if (!type) {
		console.warn('Contract type is not defined !');
		return;
	}

	let url = `/contract/${snakeCase(type)}`;

	const contractStatus =
		filterState?.u?.['special_contract.status']?.['contract.status']?.e;

	if (contractStatus) {
		const newFilterState = {
			f: {
				a: 'contract/contract',
				ff: {
					// front filter beacause status are overriden by the backfilter
					status: {
						s: [
							// s for selected
							{
								v: contractStatus, // v for value
								l: contractStatus // l for label
							}
						]
					}
				}
			}
		};

		const encoded = encodeURIComponent(
			JSON.stringify(newFilterState, null, 0)
		);
		url += `?f=${encoded}`;
	}

	goto(url);
}

export function redirectToSaleList(
	filterState: FilterStateForSaleListing
): void {
	const type =
		filterState?.u?.['special_quote.documentSubType']?.[
			'quote.documentSubType'
		]?.e;

	if (!type) {
		console.warn('Sale type is not defined !');
		return;
	}

	let url = `/sale/${snakeCase(type)}`;

	const saleStatus =
		filterState?.u?.['special_quote.status']?.['quote.status']?.e;

	if (saleStatus) {
		const newFilterState = {
			f: {
				a: 'sale/quote',
				ff: {
					status: {
						s: [
							{
								v: saleStatus,
								l: saleStatus
							}
						]
					}
				}
			}
		};
		const encoded = encodeURIComponent(
			JSON.stringify(newFilterState, null, 0)
		);
		url += `?f=${encoded}`;
	}

	goto(url);
}

export function redirectToFleetList(): void {
	goto('/fleet');
}

export function getExtranetLinks(
	apiClient: ApiClient,
	objectType: string,
	id: string | number
): {
	extranetUrl: string;
	extranetHome: string;
	extranetRaw: string;
	extranetUrlWithWk: string | undefined;
	token: string;
} {
	const baseUrl = location.origin;
	const token = apiClient?.token;
	const WkObject: WkObject =
		objectType === 'contract'
			? builder.makeWellKnownObject('view', 'contract', 'contract', id)
			: builder.makeWellKnownObject('view', 'sale', 'saleDocument', id);
	const objectLink: string = builder.makeShortWellKnown(WkObject);

	return {
		extranetUrl: `${baseUrl}${objectLink}?token=${token}`,
		extranetHome: `${baseUrl}/login?token=${token}`,
		extranetRaw: `${baseUrl}/login?token=${token}`,
		extranetUrlWithWk: getWkLink(apiClient, objectType, id, baseUrl),
		token: apiClient.token
	};
}

export function parseWlkLink(wellKnown: string): WkObjectExtended {
	const parsed: WkObject = parser.parseWellKnown(wellKnown);

	const filterState = parsed.filter
		? JSON.parse(decodeURIComponent(parsed.filter))
		: null;

	handleViewLink({
		...parsed,
		filterState
	});

	return {...parsed, filterState};
}

export function getWkLink(
	apiClient: ApiClient,
	object: string,
	itemId: string | number,
	extranetUrl: string | undefined
): string | undefined {
	const [namespace, objectName]: [string, string] | [] =
		getWkLocation(object);
	let wkLink: string | undefined;

	try {
		wkLink = buildWkLink({
			action: 'view',
			namespace,
			objectName,
			itemId,
			extranetUrl,
			token: apiClient.token
		} as WkLink);
	} catch (err) {
		console.warn(err);
		wkLink = extranetUrl;
	}

	return wkLink;
}

export function buildWkLink(
	params: WkLink,
	injectToken: boolean = true
): string {
	const siteConfig: SiteConfig = get(siteConfigStore);
	const {apiUrl} = siteConfig;
	const url: string = apiUrl.split('/api')[0];

	const itemId: number =
		typeof params.itemId === 'string'
			? parseInt(params.itemId)
			: params.itemId;

	const WkObject: WkObject = builder.makeWellKnownObject(
		params.action,
		params.namespace,
		params.objectName,
		itemId
	);
	const wellKnown: string = builder.makeShortWellKnown(WkObject);
	const baseUrl: string = params.extranetUrl || url;
	const wkLink: URL = new URL(baseUrl);
	wkLink.pathname = wellKnown;

	if (params.token && injectToken) {
		wkLink.searchParams.set('token', params.token);
	}

	return wkLink.href;
}

export function getWkLocation(type: string): [string, string] | [] {
	switch (type) {
		case 'invoice':
			return ['invoice', 'invoice'];
		case 'contract':
			return ['contract', 'contract'];
		case 'sale':
			return ['sale', 'quote']; // TODO 'sale' ?
		default:
			console.warn(`Location for ${type} is not defined!`);
			return [];
	}
}
